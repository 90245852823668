<template>
    <div>
        <Header>
            <template v-slot:header-title>
                Contact Us
            </template>
        </Header>
        <section class="contact-section">
            <div class="contact-wrapper">
                <!-- Form Column -->
                <div class="contact-form-wrapper">
                    <div class="contact-form-container">
                        <form class="contact-form" @submit.prevent="ValidateAllInputs">
                            <!-- Form Submission Errors -->
                            <div class="error-messages-wrapper" v-if="submissionErrors">
                                <div class="error-heading flex flex-row align-center">
                                    <div class="error-heading-icon">
                                        <i class="bi bi-exclamation-circle-fill"></i>
                                    </div>
                                    <div class="error-heading-title">Errors</div>
                                    </div>
                                <div class="error-body">
                                    There were errors detected in your submission. Please fix these and try again.
                                </div>
                            </div>
                            <div class="field panel-title">
                                We love questions and feedback - and we're always happy to help!
                            </div>
                            <div class="double-field margin-bottom-regular">
                                <div class="field">
                                    <label for="first-name">First Name</label>
                                    <input type="text"
                                           id="first-name"
                                           v-model="submission.firstName"
                                           :class="{ 'form-input-error': errorMessages.firstName }">
                                    <div class="error-message" v-if="errorMessages.firstName">{{errorMessages.firstName}}</div>
                                </div>
                                <div class="field">                                    
                                    <label for="last-name">Last Name</label>
                                    <input type="text"
                                           id="last-name"
                                           v-model="submission.lastName"
                                           :class="{ 'form-input-error': errorMessages.lastName }">
                                    <div class="error-message" v-if="errorMessages.lastName">{{errorMessages.lastName}}</div>
                                </div>
                            </div>
                            <div class="field margin-bottom-regular">                                
                                <label for="contact-us-company">Company</label>
                                <input type="text" id="contact-us-company" v-model="submission.company">
                            </div>
                            <div class="double-field margin-bottom-regular">
                                <div class="field">                                
                                    <label for="email">Email</label>
                                    <input type="text"
                                           id="email"
                                           v-model="submission.email"
                                           :class="{ 'form-input-error': errorMessages.email }">
                                    <div class="error-message" v-if="errorMessages.email">{{errorMessages.email}}</div>
                                </div>
                                <div class="field">                                
                                    <label for="mobile">Mobile</label>
                                    <div class="field flex-row">
                                        <AreaCodeDropdown :selectCountry="SelectCountry"
                                                          :dropdownValue="dropdownValue"
                                                          ref="mobileAreaCodeDropdown"
                                                          :user="false"
                                                          :invalid="errorMessages.areaCode">
                                                          <!-- {{dropdownValue}} -->
                                        </AreaCodeDropdown>
                                        <input type="number"
                                               id="mobile"
                                               style="margin-left: 5px !important; flex-grow: 1;"
                                               name="mobile"
                                               v-on:keypress="NumbersOnly"
                                               v-model="submission.mobile"
                                               @wheel="$event.target.blur()"
                                               :class="{ 'form-input-error': errorMessages.mobile }">
                                    </div>
                                    <div class="error-message" v-if="errorMessages.areaCode">{{errorMessages.areaCode}}</div>
                                    <div class="error-message" v-if="errorMessages.mobile">{{errorMessages.mobile}}</div>
                                </div>
                            </div>
                            <div class="field margin-bottom-regular">                                
                                <label for="message">Message</label>
                                <textarea name=""
                                          id="message"
                                          cols="30"
                                          rows="10"
                                          v-model="submission.message"
                                          :class="{ 'form-input-error': errorMessages.message }">
                                </textarea>
                                <div class="error-message" v-if="errorMessages.message">{{errorMessages.message}}</div>
                            </div>
                            <div class="field flex-row margin-bottom-regular">
                                <input  type="checkbox"
                                        id="privacy-checkbox"
                                        v-model="agreedToPrivacyPolicy">
                                <label for="privacy-checkbox" class="m-0" @click.prevent="DisableCheckboxLabelClick($event)">I have read and accept the terms within your <span class="confidentiality-link" @click="OpenLinkInNewTab('legal', 'privacy-policy')">Privacy Policy</span>.</label>
                            </div>
                            <div class="field">
                                <div class="error-message" v-if="errorMessages.privacyPolicyAgreementErrorMessage">
                                    {{errorMessages.privacyPolicyAgreementErrorMessage}}
                                </div>
                            </div>
                            <div class="captcha-wrapper">
                                <vue-recaptcha ref="recaptcha"
                                               @verify="Recaptcha(true)"
                                               @expired="Recaptcha(false)"
                                               :sitekey="sitekey"/>
                            </div>
                            <div class="field">
                                <div class="error-message" v-if="errorMessages.captchaErrorMessage">
                                    {{errorMessages.captchaErrorMessage}}
                                </div>
                            </div>
                            <!-- <input type="submit" value="Send Message"> -->
                            <button class="form-submit-button" type="submit">Send Message</button>
                        </form>
                    </div>
                </div>
                <!-- Socials Column -->
                <div class="contact-socials-wrapper">
                    <!-- Company Information -->
                    <div class="contact-socials-container">
                        <div class="contact-socials">
                            <div class="panel-title">
                                Company Information
                            </div>
                            <div class="social">
                                <i class="bi bi-geo-alt-fill"></i>
                                <div class="social-text">
                                    Building 3, 1st Floor, Concorde Park, Concorde Road, Maidenhead, Berkshire, SL6 4BY
                                </div>
                            </div>
                            <a class="social" href="mailto:info@illapa.cloud">
                                <i class="bi bi-envelope-fill"></i>
                                <div class="social-text">info@illapa.cloud</div>
                            </a>
                            <a class="social" href="tel:+442039171110">
                                <i class="bi bi-telephone-fill"></i>
                                <div class="social-text">+44 (0) 203 917 1110</div>
                            </a>
                        </div>
                    </div>
                    <!-- Socials -->
                    <div class="contact-socials-container">
                        <div class="contact-socials">
                            <div class="panel-title">
                                Company Socials
                            </div>
                            <a href="https://twitter.com/IllapaCloud" class="social" target="_blank">
                                <i class="bi bi-twitter"></i>
                                <div class="social-text" target="_blank">Twitter</div>
                            </a>
                            <a href="https://www.linkedin.com/company/illapa/" class="social" target="_blank">
                                <i class="bi bi-linkedin"></i>
                                <div class="social-text" target="_blank">LinkedIn</div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Header from "../components/header/Header.vue";
import AreaCodeDropdown from "../components/area-code-dropdown/AreaCodeDropdown.vue";
import axios from "axios";
import { VueRecaptcha } from "vue-recaptcha";

export default {
    components: {
        Header,
        AreaCodeDropdown,
        VueRecaptcha
    },
    metaInfo() {
        return {
            title: 'Contact Us | Illapa Cloud',
            meta: [
                {
                    name: 'description',
                    content: "We love questions and feedback - and we're always happy to help!"
                },
                {
                    property: 'og:title',
                    content: 'Contact Us | Illapa Cloud'
                },
                {
                    property: 'og:description',
                    content: "We love questions and feedback - and we're always happy to help!"
                },
                {
                    property: 'og:url',
                    content: 'https://illapa.cloud/company/contact-us'
                }
            ],
            link: [
                {
                    rel: 'canonical',
                    href: 'https://illapa.cloud/company/contact-us'
                }
            ]
        }
    },
    data: () => ({
        sitekey: "6LfO1QQgAAAAAJMnO8PIM9zDQwGtcHcCb3XYu6s_",
        dropdownValue: "none",
        agreedToPrivacyPolicy: false,
        captchaVerified: false,
        submissionErrors: false,
        submission: {
            firstName: "",
            lastName: "",
            email: "",
            areaCode: "",
            mobile: "",
            message: "",
            company: ""
        },
        errorMessages: {
            firstName: "",
            lastName: "",
            email: "",
            areaCode: "",
            mobile: "",
            message: "",
            privacyPolicyAgreementErrorMessage: "",
            captchaErrorMessage: "",
        },
    }),
    methods: {
        OpenLinkInNewTab(name, param) {
            const route = this.$router.resolve({ name: name, query: { legal: param } });
            window.open(route.href, '_blank');
        },
        DisableCheckboxLabelClick(e) {
            e.preventDefault();
        },
        Recaptcha(isValid) {
            this.captchaVerified = isValid;
        },
        OpenPDF() {
            window.open("/confidentiality.pdf", '_blank').focus();
        },
        ScrollToTop() {
            window.scroll(0,0);
        },
        NumbersOnly(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;

            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        SelectCountry(areaCode, index, user) {
            if (user) {
                for (let i = 0; i < this.users.length; i++) {
                    if (i == index) {
                        this.users[i].areaCode = areaCode;
                        switch(areaCode) {
                            case '+1':
                                this.users[i].dropdownValue = String(this.$refs[`userAreaCodeDropdown-${index}`][0].getFlagIcon('US'));
                                break;
                            case '+33':
                                this.users[i].dropdownValue = String(this.$refs[`userAreaCodeDropdown-${index}`][0].getFlagIcon('FR'));
                                break;
                            case '+41':
                                this.users[i].dropdownValue = String(this.$refs[`userAreaCodeDropdown-${index}`][0].getFlagIcon('CH'));
                                break;
                            case '+420':
                                this.users[i].dropdownValue = String(this.$refs[`userAreaCodeDropdown-${index}`][0].getFlagIcon('CZ'));
                                break;
                            case '+44':
                                this.users[i].dropdownValue = String(this.$refs[`userAreaCodeDropdown-${index}`][0].getFlagIcon('GB'));
                                break;
                            case '+52':
                                this.users[i].dropdownValue = String(this.$refs[`userAreaCodeDropdown-${index}`][0].getFlagIcon('MX'));
                                break;
                            case '+61':
                                this.users[i].dropdownValue = String(this.$refs[`userAreaCodeDropdown-${index}`][0].getFlagIcon('AU'));
                                break;
                            case '+86':
                                this.users[i].dropdownValue = String(this.$refs[`userAreaCodeDropdown-${index}`][0].getFlagIcon('CN'));
                                break;
                            default:
                                break;
                        }
                        this.ValidateAreaCode(this.users[i].areaCode, "users", "areaCode", true, i);
                    }
                    
                    
                    //this.ValidateUserMobileIsUnique(this.users[i].mobile, i);
                }
                this.newTotalMobileValidation();
            } else {
                this.submission.areaCode = areaCode;
                // const ref = this.$refs.mobileAreaCodeDropdown;
                switch(areaCode) {
                    case '+36':
                        this.dropdownValue = 'hu';
                        break;
                    case '+39':
                        this.dropdownValue = 'it';
                        break;
                    case '+41':
                        this.dropdownValue = 'ch';
                        break;
                    case '+44':
                        this.dropdownValue = 'gb';
                        break;
                    case '+46':
                        this.dropdownValue = 'se';
                        break;
                    case '+48':
                        this.dropdownValue = 'pl';
                        break;
                    case '+56':
                        this.dropdownValue = 'cl';
                        break;
                    case '+61':
                        this.dropdownValue = 'au';
                        break;
                    case '+63':
                        this.dropdownValue = 'ph';
                        break;
                    case '+65':
                        this.dropdownValue = 'sg';
                        break;
                    case '+351':
                        this.dropdownValue = 'pt';
                        break;
                    case '+370':
                        this.dropdownValue = 'lt';
                        break;
                    case '+372':
                        this.dropdownValue = 'ee';
                        break;
                    case '+420':
                        this.dropdownValue = 'cz';
                        break;
                    case '+421':
                        this.dropdownValue = 'sk';
                        break;
                    case '+852':
                        this.dropdownValue = 'hk';
                        break;
                    case '+972':
                        this.dropdownValue = 'il';
                        break;
                    default:
                        break;
                }
                this.ValidateAreaCode(this.submission.areaCode);
                // this.newTotalMobileValidation();
            }
        },
        async SubmitForm() {
            try {
                const submitRequest = await axios.post(`https://${location.host}/api/contactus/submit`, {
                    firstName : this.submission.firstName,
                    lastName : this.submission.lastName,
                    email : this.submission.email,
                    mobile : this.ConcatenateAreaCodeMobile(),
                    message: this.submission.message,
                    company: this.submission.company
                });
                // console.log(submitRequest);

                if (submitRequest.status === 200) {
                    window.location.reload();
                }
            } catch(err) {
                console.log(err);
                console.log(err.response.data);
            }
        },
        ValidateAllInputs() {
            // First & Last Name
            this.ValidateGeneric(this.submission.firstName, "firstName");
            this.ValidateGeneric(this.submission.lastName, "lastName");
            // Email
            this.ValidateEmail(this.submission.email);
            this.ValidateGeneric(this.submission.email, "email");
            // Area Code
            this.ValidateAreaCode(this.submission.areaCode);
            // Mobile
            this.ValidateMobile(this.submission.mobile);
            this.ValidateGeneric(this.submission.mobile, "mobile");
            // Message
            this.ValidateGeneric(this.submission.message, "message");
            // Privacy Policy
            this.ValidatePrivacyPolicyAgreement();
            // reCAPTCHA
            this.ValidateCaptcha();

            this.submissionErrors = false;
            for (let [key, value] of Object.entries(this.errorMessages)) { // eslint-disable-line no-unused-vars
                let childKey = key;

                for (let [key, value] of Object.entries(this.errorMessages[childKey])) { // eslint-disable-line no-unused-vars
                    if (value != '' || !this.agreedToPrivacyPolicy) {
                        this.submissionErrors = true;
                    }
                }
            }

            if (!this.submissionErrors && this.agreedToPrivacyPolicy && this.captchaVerified) {
                this.SubmitForm();
            } else {
                this.ScrollToTop();
            }
        },
        ValidateGeneric(value, errorValue) {
            if (!value) {
                this.errorMessages[errorValue] = "This field is required.";
            }
        },
        ValidateAreaCode(value) {
            if (!value) {
                this.errorMessages.areaCode = "Please select a country code.";
            } else {
                this.errorMessages.areaCode = "";
            }
        },
        ValidateMobile(value) {
            if (new RegExp("^[0-9]{10,11}$").test(value)) {
                this.errorMessages.mobile = "";
            } else {
                this.errorMessages.mobile = "This mobile number is invalid.";
            }
        },
        ValidateEmail(value) {
            if (new RegExp("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.(com|cloud|za|co.uk|net|biz|org|int|tel|xxx|info|px|tv|mobi|travel|com.au|ae|ca|cz|dk|com.eg|eu|uk|hk|jp|no|nz|sg|sk|ai)$").test(value)) {
                this.errorMessages.email = "";
            } else {
                this.errorMessages.email = "This email address is invalid.";
            }
        },
        ValidatePrivacyPolicyAgreement() {
            this.agreedToPrivacyPolicy ? this.errorMessages.privacyPolicyAgreementErrorMessage = "" : this.errorMessages.privacyPolicyAgreementErrorMessage = "Please confirm that you have read, understood and agree to our Privacy Policy.";
        },
        ValidateCaptcha() {
            this.captchaVerified ? this.errorMessages.captchaErrorMessage = "" : this.errorMessages.captchaErrorMessage = "Please check the reCAPTCHA box.";
        },
        ConcatenateAreaCodeMobile() {
            const mobile = String(this.submission.areaCode) + String(this.submission.mobile);
            return mobile;
        }
    },
    watch: {
        "submission.firstName": function() {
            this.errorMessages.firstName = "";
            this.ValidateGeneric(this.submission.firstName, "firstName");
        },
        "submission.lastName": function() {
            this.errorMessages.lastName = "";
            this.ValidateGeneric(this.submission.lastName, "lastName");
        },
        "submission.areaCode": function() {
            this.errorMessages.areaCode = "";
            this.ValidateAreaCode(this.submission.areaCode);
            this.ValidateGeneric(this.submission.areaCode, "areaCode");
        },
        "submission.mobile": function() {
            this.errorMessages.mobile = "";
            this.ValidateMobile(this.submission.mobile);
            this.ValidateGeneric(this.submission.mobile, "mobile");
        },
        "submission.email": function() {
            this.errorMessages.email = "";
            this.ValidateEmail(this.submission.email);
            this.ValidateGeneric(this.submission.email, "email");
        },
        "submission.message": function() {
            this.errorMessages.message = "";
            this.ValidateGeneric(this.submission.message, "message");
        },
        agreedToPrivacyPolicy: function() {
            this.errorMessages.privacyPolicyAgreementErrorMessage = "";
            this.ValidatePrivacyPolicyAgreement();
        },
        captchaVerified: function() {
            this.captchaErrorMessage = "";
            this.ValidateCaptcha();
        },
    }
}
</script>

<style scoped>
.contact-section .contact-wrapper {
    display: flex;
    justify-content: space-between;
    padding: 50px 10px 150px 10px;
    max-width: 1450px;
    margin-left: auto;
    margin-right: auto;
}
.contact-section .contact-wrapper .contact-form-wrapper,
.contact-section .contact-wrapper .contact-socials-wrapper {
    padding: 0 10px;
}
.contact-section .contact-wrapper .contact-form-wrapper {
    width: 60%;
    flex: 0 0 60%;
}
.contact-section .contact-wrapper .contact-form-wrapper .error-messages-wrapper {
    border: 2px solid var(--error-dark);
    background-color: var(--error-light);
    color: var(--error-dark);
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 40px;
}
.contact-section .contact-wrapper .contact-form-wrapper .error-messages-wrapper .error-heading {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 1rem;
}
.contact-section .contact-wrapper .contact-form-wrapper .error-messages-wrapper .error-heading .error-heading-icon {
    margin-right: 10px;
}
.contact-section .contact-wrapper .contact-socials-wrapper {
    display: flex;
    flex-direction: column;
    width: 40%;
    flex: 0 0 40%;
}
.contact-section .contact-wrapper .contact-form-wrapper .contact-form-container,
.contact-section .contact-wrapper .contact-socials-wrapper .contact-socials-container {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 3px 52px 0 rgba(0,0,0,0.08);
    padding: 30px;
}
.contact-section .contact-wrapper .contact-socials-wrapper .contact-socials-container {
    margin-bottom: 20px;
}
.contact-section .contact-wrapper .contact-form-wrapper .contact-form-container .contact-form {
    display: flex;
    flex-flow: column nowrap;
}
.form-submit-button {
    font-family: inherit;
    font-size: 16px;
    padding: 15px;
    max-width: 136px;
    text-align: center;
    margin-top: 20px;
    font-weight: 500;
    cursor: pointer;
    border-radius: 4px;
    border: none;
    background-color: var(--brand-accent-blue);
    color: #fff;
    transition: box-shadow .2s ease;
}
.form-submit-button:hover {
    box-shadow: 0 9px 30px 0 #007bfc4d;
}
.contact-section .contact-wrapper .contact-form-wrapper .contact-form-container .contact-form .double-field {
    display: flex;
    justify-content: space-between;
}
.contact-section .contact-wrapper .contact-form-wrapper .contact-form-container .contact-form .field {
    display: flex;
    flex-flow: column nowrap;
    flex: 1;
}
.contact-section .contact-wrapper .contact-form-wrapper .contact-form-container .contact-form .field input[type=checkbox] {
    margin: 0;
    margin-right: 10px;
}
.contact-section .contact-wrapper .contact-form-wrapper .contact-form-container .contact-form .field input,
.contact-section .contact-wrapper .contact-form-wrapper .contact-form-container .contact-form .field textarea {
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 2px solid #E1E3EB;
    transition: border 0.15s ease;
}
.contact-section .contact-wrapper .contact-form-wrapper .contact-form-container .contact-form .field input:focus,
.contact-section .contact-wrapper .contact-form-wrapper .contact-form-container .contact-form .field textarea:focus {
    outline: none !important;
    border-color: var(--brand-accent-blue);
}
.contact-section .contact-wrapper .contact-form-wrapper .contact-form-container .contact-form .field input {
    line-height: 30px;
    padding: 0 10px;
}
.contact-section .contact-wrapper .contact-form-wrapper .contact-form-container .contact-form .field textarea {
    padding: 15px;
    resize: none;
}
.contact-section .contact-wrapper .contact-form-wrapper .contact-form-container .contact-form .field label {
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    margin-bottom: 10px;
    color: var(--text-dark-terniary);
}
.contact-section .contact-wrapper .contact-form-wrapper .contact-form-container .contact-form .field input[type=number] {
    -moz-appearance: textfield;
}
.contact-section .contact-wrapper .contact-form-wrapper .contact-form-container .contact-form .double-field .field:first-child {
    margin-right: 1rem;
}
.contact-section .contact-wrapper .contact-form-wrapper .contact-form-container .contact-form .field .error-message {
    color: #fd6868;
    font-size: 14px;
    font-weight: 500;
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 4px;
    background: var(--error-light);
}
.contact-section .contact-wrapper .contact-socials-wrapper .contact-socials-container .contact-socials {
    display: flex;
    flex-direction: column;
}
.contact-section .contact-wrapper .contact-socials-wrapper .contact-socials-container .contact-socials .social {
    display: flex;
    align-items: center;
}
.contact-section .contact-wrapper .contact-socials-wrapper .contact-socials-container .contact-socials .social:not(:last-child) {
    margin-bottom: 20px;
}
.contact-section .contact-wrapper .contact-socials-wrapper .contact-socials-container .contact-socials .social i {
    margin-right: 20px;
    color: var(--brand-primary-blue);
    font-size: 25px;
}
.contact-section .contact-wrapper .contact-socials-wrapper .contact-socials-container .contact-socials .social .social-text {
    position: relative;
    text-decoration: none;
    color: var(--text-dark-terniary);
}
.contact-section .contact-wrapper .contact-socials-wrapper .contact-socials-container .contact-socials .social .social-text::after {
    content: "";
    height: 1px;
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 0;
    background-color: var(--dark-default);
    transition: width 0.2s ease;
}
.contact-section .contact-wrapper .contact-socials-wrapper .contact-socials .social .social-text:hover::after {
    width: 0;
}
.form-input-error {
    border-color: #f87373 !important;
    color: #f87373;
}
.captcha-wrapper {
    margin-top: 20px;
    margin-bottom: 10px;
}
.panel-title {
    color: var(--text-dark-secondary);
    font-size: 22px;
    font-weight: 300;
    margin-bottom: 30px;
}
.confidentiality-link {
    cursor: pointer;
    text-decoration: underline;
    color: var(--dark-default);
}
@media only screen and (max-width: 600px) {
    .contact-section .contact-wrapper .contact-socials-wrapper {
        flex-direction: column;
    }
    .contact-section .contact-wrapper .contact-socials-wrapper .contact-socials-container {
        width: 100%;
        flex: 0 0 100%;
    }
    .double-field {
        flex-direction: column;
    }
    .field {
        width: 100%;
    }
}
@media only screen and (min-width: 600px) and (max-width: 992px) {
    .contact-section .contact-wrapper .contact-socials-wrapper {
        flex-direction: row;
    }
    .contact-section .contact-wrapper .contact-socials-wrapper .contact-socials-container {
        width: 50%;
        flex: 0 0 50%;
    }
    .contact-section .contact-wrapper .contact-socials-wrapper .contact-socials-container:first-child {
        margin-right: 20px;
    }
}
@media only screen and (max-width: 992px) {
    .contact-section .contact-wrapper {
        flex-direction: column;
    }
    .contact-section .contact-wrapper .contact-form-wrapper,
    .contact-section .contact-wrapper .contact-socials-wrapper {
        width: 100%;
        flex: 0 0 100%;
    }
    .contact-section .contact-wrapper .contact-form-wrapper {
        margin-bottom: 20px;
    }
}
</style>